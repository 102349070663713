import React, { createContext, useState, useMemo, useContext, useEffect } from "react"
import { useHistory } from "react-router"
import Api from '../__helpers/api'

export const AuthDataContext = createContext({})

const initialAuthData = {
    isLogged: false,
    user: {}
}

const AuthDataProvider = props => {
    const history = useHistory()
    const [authData, setAuthData] = useState(initialAuthData)
    const [state, setState] = useState({
        refresh: null
    })

    const getAuthData = () => {
        let currentAuthData = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : initialAuthData

        setAuthData(currentAuthData)

        if (currentAuthData.isLogged) {
            refresh()
        }

    }

    const getHomeUrl = () => {
        if (authData.isLogged) {
            const type = authData.user.user_type_id

            return type === 8 ? '/iaaa-home' : type === 1 ? '/student-home' : '/center-students'
        } else {
            return '/'
        }
    }

    const getHomeUrlonLogo = () => {
        if (authData.isLogged) {
            const type = authData.user.user_type_id

            return type === 8 ? '/iaaa-home' : type === 1 ? '/student-home' : '/center-home'
        } else {
            return '/'
        }
    }

    const refresh = () => {
        Api.get('profile')
            .then(response => {
                let currentAuthData = response.data
                login(currentAuthData)
            }).catch(err => {
                localStorage.removeItem('auth')
                setAuthData(initialAuthData)
            })
    }

    const login = newAuthData => {

        if (!newAuthData.is_student && newAuthData.confirmed == 0) {
            localStorage.setItem('auth-activated', JSON.stringify(0))
            return
        }
        let state = {
            isLogged: true,
            user: newAuthData
        }
        localStorage.setItem('auth', JSON.stringify(state))

        setAuthData(state)
        setState(prev => ({
            ...prev,
            refresh: new Date()
        }))

        if ((JSON.parse(localStorage.getItem('authData')) == 0) && !history.location.pathname.includes('buy')) pushToHome(newAuthData)
    }

    const pushToHome = newAuthData => {
        const lastVal = JSON.parse(localStorage.getItem('authData')) + 1

        localStorage.setItem('authData', JSON.stringify(lastVal))
        const type = newAuthData.user_type_id
        const homePath = type === 8 ? '/iaaa-home' : type === 1 ? '/student-home' : '/center-home'

        window.location.href = homePath
    }

    useEffect(() => {
        if (!auth.isLogged) {
            localStorage.setItem('authData', JSON.stringify(0))
        }
    }, [authData.isLogged])

    const logout = () => {
        // localStorage.clear();
        localStorage.removeItem('auth')
        // setAuthData(initialAuthData);
        localStorage.setItem('authData', JSON.stringify(0))
        window.location = '/'

        localStorage.clear()
        // for (let i = 0; i <= 19; i++) {
        //     localStorage.removeItem(`test_${i}`)
        //     localStorage.removeItem(`test_${i}_video_questions`)
        //     localStorage.removeItem(`exam_${i}`)
        // }
    }

    const setVideoCommentTutorToSeen = () => {
        const updatedAuthData = authData
        updatedAuthData.user['show_video_comments_tutor'] = 0

        setAuthData(updatedAuthData)
    }

    const seTestButtonTutorToSeen = () => {
        const updatedAuthData = authData
        updatedAuthData.user['show_test_button'] = 0

        setAuthData(updatedAuthData)
    }

    useMemo(() => {
        getAuthData()
    }, [])

    const auth = useMemo(() => ({ ...authData, setVideoCommentTutorToSeen, seTestButtonTutorToSeen, login, refresh, logout, getHomeUrl, getHomeUrlonLogo, pushToHome }), [authData])

    return <AuthDataContext.Provider value={auth} {...props} />
}

export const useAuthDataContext = () => useContext(AuthDataContext)

export default AuthDataProvider